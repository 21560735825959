import React from "react";
import './b1_enroll_step.css'
import { DatePicker,Modal,Cascader,Upload, Button, Icon } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment'
import options from '../../data/cascader-address-options'
const props = {
  action: '//www.mocky.io/v2/5cc8019d300000980a055e76',
  listType: 'picture',
  // defaultFileList: [...fileList],
}

export default class B1EnrollStep extends React.Component{
  constructor (props) {
    super(props);
    // this.showCode = this.showCode.bind(this);
    // this.hideCode = this.hideCode.bind(this);

  }
  state = {
    enroll:false,
    step:1,
    infoReaded:false,
    blood:'',
    education:'',
    clothsize:'',
    income:'',
    linkmanrelationship:'',
    showwarning:false
  }

  nextStep=()=>{
    if(this.state.step===1){
      if(!this.state.infoReaded){
        Modal.warning({
          title: '请勾选已阅读',
        })
        return false
      }
    }
    if(this.state.step===2){
      if(!this.state.name||!this.state.sex||!this.state.idNum||!this.state.birth){
        // Modal.warning({
        //     title: '姓名未填写',
        // })
        this.setState({
          showwarning:true
        })
        return  false
      }

    }
    if(this.state.step===3){
      if(!this.state.blood||!this.state.city||!this.state.clothsize){
        this.setState({
          showwarning:true
        })
        return  false
      }
    }

    this.setState({
      step:this.state.step+1,
      showwarning:false
    })
  }
  lastStep=()=>{
    this.setState({
      step:this.state.step-1
    })
  }
  readRaceInfo=()=>{
    this.setState({
      infoReaded:!this.state.infoReaded
    })
  }
  selectSex=(e)=>{
    this.setState({
      sex:e.target.value
    })
  }
  selectBirth=(date, dateString)=> {
    console.log(date, dateString)
    this.setState({
      birth:dateString
    })
  }

  nameInput=(e)=>{
    console.log('name:',e.target.value)
    this.setState({
      name:e.target.value
    })
    // console.log('statename:',this.state.name)
  }

  selectIdType=(e)=>{
    console.log('idType:',e.target.value)
    this.setState({
      idType:e.target.value
    })
  }
  idNumInput=(e)=>{
    console.log('idNum:',e.target.value)
    this.setState({
      idNum:e.target.value
    })
  }
  selectBlood=(e)=>{
    console.log("blood",e.target.value)
    this.setState({
      blood:e.target.value
    })
  }
  jobInput=(e)=>{
    console.log("job",e.target.value)
    this.setState({
      job:e.target.value
    })
  }

  selectCity=(e, selectedOptions)=>{
    console.log("city", selectedOptions[0].label)
    const city=selectedOptions[0].label+'-'+selectedOptions[1].label+'-'+selectedOptions[2].label
    this.setState({
      city
    })
    console.log("city", city)
  }

  selectEducation=(e)=>{
    console.log("education",e.target.value)
    this.setState({
      education:e.target.value
    })
  }
  selectClothsize=(e)=>{
    console.log("clothsize",e.target.value)
    this.setState({
      clothsize:e.target.value
    })
  }

  locationInput=(e)=>{
    console.log("location",e.target.value)
    this.setState({
      location:e.target.value
    })
  }
  selectIncome=(e)=>{
    console.log("income",e.target.value)
    this.setState({
      income:e.target.value
    })
  }
  phoneInput=(e)=>{
    console.log("phone",e.target.value)
    this.setState({
      phone:e.target.value
    })
  }
  emailInput=(e)=>{
    console.log("email",e.target.value)
    this.setState({
      email:e.target.value
    })
  }
  linkmanInput=(e)=>{
    console.log("linkman",e.target.value)
    this.setState({
      linkman:e.target.value
    })
  }
  linkmanPhoneInput=(e)=>{
    console.log("linkmanphone",e.target.value)
    this.setState({
      linkmanphone:e.target.value
    })
  }

  selectLinkmanRelationship=(e)=>{
    console.log("linkmanrelationship",e.target.value)
    this.setState({
      linkmanrelationship:e.target.value
    })
  }

  cancelEnroll=()=>{
    this.setState({
      showCancel:true
    })
    console.log("取消报名")
  }
  hideCancel=()=>{
    this.setState({
      showCancel:false
    })
  }
  confirmCancel=()=>{
    this.setState({
      step:1
    })
  }
  backToEvents=()=>{
    this.setState({
      step:1
    })
  }




  render() {
    const color="black"
    const contents=[{title: '一、主办单位', content: '中国田径协会、衢州市人民政府'}, {title: '二、指导单位', content: '浙江省体育局'}, {title: '三、承办单位', content: '衢州市体育局、浙江省体育竞赛中心'}, {title: '四、协办单位', content: '柯城区人民政府、西区管委会、衢江区人民政府、衢州市体育总会、衢州市文旅局、衢州市公安局、衢州市卫生和计划生育委员会、衢州市国有资产监督管理委员会、衢州市金控集团衢州市规划局、衢州市城投集团、衢州市气'}]

    const contentlist=contents.map((content,index)=><div key={index} className="content"><p>{content.title}</p><p>{content.content}</p><img alt="hello" style={{display:content.img?'block':'none'}} src={content.img} /> </div>)
    const raceName='2019特步衢州马拉松竞赛规程'

    const dateFormat='YYYY-MM-DD'
    return(
      <div className="enroll-container">
        <div className="step-title-list">
          <div style={{color:color}}>阅读文档</div>
          <div style={{color:this.state.step>=2?color:'#999999'}}>填写信息</div>
          <div style={{color:this.state.step>=5?color:'#999999'}}>确认信息</div>
          <div className="info-input-desc">证件信息/补充信息/联系方式</div>
          <div style={{color:this.state.step>=6?color:'#999999'}}>支付方式</div>
          <div style={{color:this.state.step>=7?color:'#999999'}}>报名审核</div>
        </div>
        <div className="progress-line-container">
          <div className="step-num" style={{background:this.state.step===1?"white":color,color:this.state.step===1?color:'white',borderColor:color}}>1</div>
          <div className="progress-line" style={{background:this.state.step>1?color:'#e5e4e4'}}></div>
          <div className="step-num" style={{background:this.state.step<=4?"white":color,color:this.state.step<2?'black':this.state.step<=4?color:'white',borderColor:this.state.step<2?'white':color}}>2</div>
          <div className="progress-line" style={{background:this.state.step>4?color:'#e5e4e4'}}></div>
          <div className="step-num" style={{background:this.state.step<=5?"white":color,color:this.state.step<5?'black':this.state.step===5?color:'white',borderColor:this.state.step<5?'white':color}}>3</div>
          <div className="progress-line" style={{background:this.state.step>5?color:'#e5e4e4'}}></div>
          <div className="step-num" style={{background:this.state.step<=6?"white":color,color:this.state.step<6?'black':this.state.step===6?color:'white',borderColor:this.state.step<6?'white':color}}>4</div>
          <div className="progress-line" style={{background:this.state.step>6?color:'#e5e4e4'}}></div>
          <div className="step-num" style={{background:this.state.step<=7?"white":color,color:this.state.step<7?'black':this.state.step===7?color:'white',borderColor:this.state.step<7?'white':color}}>5</div>
        </div>
        <div className="info-input-steps" style={{display:this.state.step>1&&this.state.step<5?'flex':'none'}}>
          <div className="info-input-step" style={{background:this.state.step<3?'white':color,color:this.state.step<3?color:'white'}}>
            <div className="info-input-step-before" style={{borderTopColor:this.state.step<3?'white':color,borderBottomColor:this.state.step<3?'white':color}}></div>
            <div style={{flex:1}}>证件信息</div>
            <div className="info-input-step-after" style={{borderLeftColor:this.state.step<3?'white':color}}></div>
          </div>
          <div className="info-input-step" style={{background:this.state.step<4?'white':color,color:this.state.step<3?'#999999':this.state.step===3?color:'white'}}>
            <div className="info-input-step-before" style={{borderTopColor:this.state.step<4?'white':color,borderBottomColor:this.state.step<4?'white':color}}></div>
            <div style={{flex:1}}>补充信息</div>
            <div className="info-input-step-after" style={{borderLeftColor:this.state.step<4?'white':color}}></div>
          </div>
          <div className="info-input-step" style={{background:this.state.step<5?'white':color,color:this.state.step<4?'#999999':this.state.step===4?color:'white'}}>
            <div className="info-input-step-before"  style={{borderTopColor:this.state.step<5?'white':color,borderBottomColor:this.state.step<5?'white':color}}></div>
            <div style={{flex:1}}>联系方式</div>
            <div className="info-input-step-after" style={{borderLeftColor:this.state.step<5?'white':color}}></div>
          </div>
        </div>

        <div className="step-content">
          {this.state.step===1&&<div>
            <p>竞赛规程</p>
            <p className="race-name">{raceName}</p>
            <div>
              {contentlist}
            </div>
            <div className="checkbox-text">
              <input type="checkbox" checked={this.state.infoReaded} onChange={this.readRaceInfo}/>
              <div>我已阅读文档</div>
            </div>
          </div>}


          {this.state.step===2&&<div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title" >
                <span style={{color:color}}>*</span>
                <div>姓名:</div>
              </div>
              <input  className="info-edit" placeholder="请输入选手姓名"  value={this.state.name||''} onChange={this.nameInput}/>
              {!this.state.name&&this.state.showwarning&&<div className="warning-text" >未填写</div>}
            </div>

            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <span style={{color:color}}>*</span>
                <div>性别:</div>
              </div>
              <div className="info-sex">
                <div className="sex-radio-container"><input type='radio' className="radio" checked={this.state.sex==='man'} value="man" onChange={this.selectSex}/>男</div>
                <div className="sex-radio-container"><input type="radio" className="radio" checked={this.state.sex==='female'} value="female" onChange={this.selectSex}/>女</div>
              </div>
              {!this.state.sex&&this.state.showwarning&&<div className="warning-text" >未选择</div>}
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <span style={{color:color}}>*</span>
                <div>证件类型:</div>
              </div>
              <div className="info-select">
                <select onChange={this.selectIdType} value={this.state.idType}>
                  <option value="idCard">身份证</option>
                  <option value="passport">护照</option>
                </select>
              </div>

            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <span style={{color:color}}>*</span>
                <div>证件号码:</div>
              </div>
              <input className="info-edit" placeholder="请填写证件号码" value={this.state.idNum||''} onChange={this.idNumInput}/>
              {!this.state.idNum&&this.state.showwarning&&<div className="warning-text" >未填写</div>}
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <span style={{color:color}}>*</span>
                <div>出生日期:</div>
              </div>
              <div className="select-birth">
                <DatePicker className="date-picker" onChange={this.selectBirth} locale={locale} placeholder="请选择出生日期" value={this.state.birth?moment(this.state.birth, dateFormat):null} defaultValue={null} format={dateFormat}/>
              </div>
              {!this.state.birth&&this.state.showwarning&&<div className="warning-text" >未选择日期</div>}
            </div>
          </div>}


          {this.state.step===3&&<div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <span style={{color:color}}>*</span>
                <div>血型:</div>
              </div>
              <div className="info-select">
                <select value={this.state.blood} onChange={this.selectBlood}>
                  <option value="" disabled  hidden>请选择血型</option>
                  <option value='O'>O</option>
                  <option value='A'>A</option>
                  <option value='B'>B</option>
                  <option value='AB'>AB</option>
                </select>
              </div>
              {!this.state.blood&&this.state.showwarning&&<div className="warning-text" >未选择血型</div>}
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <span style={{color:color}}>*</span>
                <div>国家地区:</div>
              </div>
              <div className="info-country" >
                <Cascader options={options}  style={{border:"none",width:'100%',height:'100%'}} onChange={this.selectCity} placeholder="请输入国家地区" />
              </div>
              {/*<input className="info-edit" value={this.state.country||''} onChange={this.selectCountry} placeholder="请输入国家地区"/>*/}
              {!this.state.country&&this.state.showwarning&&<div className="warning-text" >未选择国家地区</div>}
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>职业:</div>
              </div>
              <input className="info-edit" value={this.state.job||''} onChange={this.jobInput} placeholder="请填写职业"/>
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>学历:</div>
              </div>
              <div className="info-select">
                <select value={this.state.education} onChange={this.selectEducation}>
                  <option value="" disabled  hidden>请选择学历</option>
                  <option>小学</option>
                  <option>初中</option>
                  <option>高中</option>
                  <option>大学</option>
                  <option>硕士及以上</option>
                </select>
              </div>
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <span style={{color:color}}>*</span>
                <div>衣服尺码:</div>
              </div>
              <div className="info-select">
                <select value={this.state.clothsize} onChange={this.selectClothsize}>
                  <option value="" disabled hidden>请选择衣服尺寸</option>
                  <option>S</option>
                  <option>M</option>
                  <option>L</option>
                  <option>XL</option>
                  <option>XXL</option>
                  <option>XXXL</option>
                </select>
              </div>
              {!this.state.clothsize&&this.state.showwarning&&<div className="warning-text" >未选择衣服尺码</div>}
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>居住地:</div>
              </div>
              <input className="info-edit" value={this.state.location||''} onChange={this.locationInput} placeholder="请输入居住地址"/>
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>月收入:</div>
              </div>
              <div className="info-select">
                <select value={this.state.income} onChange={this.selectIncome}>
                  <option value="" disabled  hidden>请选择月收入</option>
                  <option>3000以下</option>
                  <option>3000~5000</option>
                  <option>5000~10000</option>
                  <option>10000+</option>
                </select>
              </div>
            </div>
            <div className="info-input" style={{alignItems:'flex-start',height:'auto'}}>
              <div style={{flex:1}} className="info-input-title">
                <span style={{color:color}}>*</span>
                <div>上传成绩证书:</div>

              </div>
              <div className="info-upload">
                <Upload {...props}>
                  <Button>
                    <Icon type="upload" /> 上传图片
                  </Button>
                </Upload>
              </div>


            </div>
          </div>}


          {this.state.step===4&&<div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>手机号码:</div>
              </div>
              <input className="info-edit" value={this.state.phone||''} onChange={this.phoneInput} placeholder="请填写正确的手机号码"/>
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>邮箱:</div>
              </div>
              <input className="info-edit" value={this.state.email||''} onChange={this.emailInput}  placeholder="请填写正确的邮箱"/>
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>紧急联系人:</div>
              </div>
              <input className="info-edit" value={this.state.linkman||''} onChange={this.linkmanInput} placeholder="请填写紧急联系人姓名"/>
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>联系人号码:</div>
              </div>
              <input className="info-edit" value={this.state.linkmanphone||''} onChange={this.linkmanPhoneInput} placeholder="请填写正确的号码"/>
            </div>
            <div className="info-input">
              <div style={{flex:1}} className="info-input-title">
                <div>联系人关系:</div>
              </div>
              <div className="info-select">
                <select value={this.state.linkmanrelationship} onChange={this.selectLinkmanRelationship}>
                  <option value="" disabled  hidden>请选择联系人关系</option>
                  <option>父母</option>
                  <option>夫妻</option>
                  <option>兄弟姐妹及其他亲友</option>
                  <option>同事</option>
                  <option>朋友</option>
                </select>
              </div>
            </div>
          </div>}


          {this.state.step===5&&<div>
            <div className="confirm-title" >
              <div className="title-line-left"></div>
              <div>确认信息</div>
              <div className="title-line-right"></div>
            </div>
            <div style={{marginBottom:'30px'}}>
              <div className="classify-title" >
                <div className="vertical-line" style={{background:color}}></div>
                <div>项目</div>
              </div>
              <div className="classify-info-content">苏州太湖马拉松</div>
            </div>
            <div style={{marginBottom:'30px'}}>
              <div className="classify-title" >
                <div className="vertical-line" style={{background:color}}></div>
                <div>证件信息</div>
              </div>
              <div className="classify-info-content">
                {this.state.name&&<div className="info-item">姓名: {this.state.name}</div>}
                {this.state.sex&&<div className="info-item">性别: {this.state.sex}</div>}
                {this.state.idType&&<div className="info-item">证件类型: {this.state.idType}</div>}
                {this.state.idNum&&<div className="info-item">证件号码: {this.state.idNum}</div>}
                {this.state.birth&&<div className="info-item">出生日期: {this.state.birth}</div>}
              </div>
            </div>
            <div style={{marginBottom:'30px'}}>
              <div className="classify-title">
                <div className="vertical-line" style={{background:color}}></div>
                <div>个人信息</div>
              </div>
              <div className="classify-info-content">
                {this.state.blood&&<div className="info-item">血型: {this.state.blood}</div>}
                {this.state.country&&<div className="info-item">国家地区: {this.state.country}</div>}
                {this.state.job&&<div className="info-item">职业: {this.state.job}</div>}
                {this.state.education&&<div className="info-item">学历: {this.state.education}</div>}
                {this.state.income&&<div className="info-item">月收入: {this.state.income}</div>}
                {this.state.location&&<div className="info-item">居住地: {this.state.location}</div>}
                {this.state.clothsize&&<div className="info-item">衣服尺码: {this.state.clothsize}</div>}
              </div>
            </div>
            <div style={{marginBottom:'30px'}}>
              <div className="classify-title">
                <div className="vertical-line" style={{background:color}}></div>
                <div>联系方式</div>
              </div>
              <div className="classify-info-content">
                {this.state.phone&&<div className="info-item">手机号: {this.state.phone}</div>}
                {this.state.email&&<div className="info-item">邮箱: {this.state.email}</div>}
                {this.state.linkman&&<div className="info-item">紧急联系人: {this.state.linkman}</div>}
                {this.state.linkphone&&<div className="info-item">联系人号码: {this.state.linkphone}</div>}
                {this.state.linkmanrelationship&&<div className="info-item">联系人关系: {this.state.linkmanrelationship}</div>}
              </div>
            </div>

          </div>}


          {this.state.step === 6 &&
          <div className="pay-container">
            <div className="pay-event-name">项目名称： 苏州簇格马拉松-全马</div>
            <div className="pay-money">支付金额： ￥120</div>
            <div style={{width:'100%',height:'2px',background:'#e5e4e4'}}></div>
            <div className="pay-way-title">支付方式</div>
            <div className="pay-way">
              <div className="Alipay"><img alt="支付宝" src={require('../../images/b1/pay.png')}/></div>
              <div className="WXpay"><img alt="微信支付" src={require('../../images/b1/wpay.png')}/></div>
            </div>
          </div>
          }

          {this.state.step === 7 &&
          <div className='waiting-review'>
            <div className='title'>信息已提交，等待审核/抽签</div>
            <div className="tip">将在7个工作日完成审核，并通过短信方式通知</div>
            <div className='final-btn' onClick={this.backToEvents}>我知道了</div>
          </div>

          }

          <div className="step-btn">
            {this.state.step>1&&this.state.step<6&&<div className="last-step" onClick={this.lastStep}>上一步</div>}
            {this.state.step===6&&<div className="last-step" onClick={this.cancelEnroll}>取消报名</div>}
            {this.state.step<6&&<div className="next-step"  onClick={this.nextStep}>下一步</div>}
            {this.state.step===6&&<div className="next-step"  onClick={this.nextStep}>立即支付</div>}
            {/*{this.state.step===8&&<div className="next-step" >确定</div>}*/}
          </div>
        </div>

      </div>
    )
  }
}